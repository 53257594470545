<template>
  <div>
    <b-row>
      <b-col class="d-flex">
        <b-form-input
          id="search"
          v-model="search"
          :placeholder="$t('Mã tài khoản/ Số tài khoản')"
          class="mr-2 w-25"
        />
        <b-button
          variant="primary"
          class="mr-2"
          @click="fetchBankAccount"
        >
          {{ $t('Tìm kiếm') }}
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="createBankAccountModal.show()"
        >
          {{ $t('Thêm tài khoản') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          small
          :fields="fields"
          :items="items"
          responsive
          class="mt-2"
          show-empty
        >
          <!-- A virtual column -->
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(bank_number)="data">
            <a
              href="#"
              @click="editBankAccount(data.item)"
            >{{ data.value }}</a>
          </template>

          <template #cell(created_at)="data">
            {{ formatDateTimeDb(data.value) }}
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-danger"
              size="sm"
              @click="deleteBankAccountHandle(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #empty="scope">
            <b-row>
              <b-col>
                <b-alert
                  show
                  variant="info"
                  class="text-center"
                >
                  {{ $t('Không có dữ liệu') }}
                </b-alert>
              </b-col>
            </b-row>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-md-end">
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          :first-text="$t('Trang đầu')"
          :prev-text="$t('Trang trước')"
          :next-text="$t('Trang tiếp')"
          :last-text="$t('Trang cuối')"
          @change="changePage"
        />
      </b-col>
    </b-row>
    <create-bank-account-modal
      ref="createBankAccountModal"
      @created="fetchBankAccount"
    />
    <update-bank-account-modal
      ref="updateBankAccountModal"
      @updated="fetchBankAccount"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BAlert,
  BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import CreateBankAccountModal from '@/views/bank-account/CreateBankAccountModal.vue'
import UpdateBankAccountModal from '@/views/bank-account/UpdateBankAccountModal.vue'
import { formatDateTimeDb } from '@/libs/timezone'
import { useI18n } from '@core/utils/utils'
import Swal from 'sweetalert2'
import { useListBankAccount } from './useBankAccount'

export default {
  name: 'BankAccount',
  components: {
    UpdateBankAccountModal,
    CreateBankAccountModal,
    // vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BAlert,
    BPagination,
  },
  setup() {
    const { t } = useI18n()
    const {
      getBankAccounts,
      deleteBankAccount,
    } = useListBankAccount()

    const createBankAccountModal = ref(null)
    const updateBankAccountModal = ref(null)
    const search = ref('')
    const bankStatus = ref('')
    const bankStatusOptions = [
      { label: t('Đã phê duyệt'), value: 'active' },
      { label: t('Chờ phê duyệt'), value: 'pending' },
      { label: t('Dừng sử dụng'), value: 'stopped' },
    ]

    // table
    const fields = [
      { key: '#', label: '#' },
      { key: 'bank_number', label: t('Số tài khoản') },
      {
        key: 'bank_account',
        label: t('tên tài khoản'),
        formatter: value => value.toUpperCase(),
      },
      { key: 'bank_master.name', label: t('Ngân hàng') },
      { key: 'created_at', label: t('thời gian tạo') },
      { key: 'action', label: '' },
    ]
    const items = ref([])

    // pagination
    const pagination = ref({
      total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
    })

    const fetchBankAccount = () => {
      getBankAccounts({
        search: search.value,
        page: pagination.value.current_page,
      }).then(res => {
        items.value = res.data
        pagination.value = res.meta.pagination
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchBankAccount()
    }

    const editBankAccount = row => {
      updateBankAccountModal.value.show(row)
    }

    const deleteBankAccountHandle = row => {
      Swal.fire({
        text: t('Bạn có chắc chắn muốn xóa tài khoản này?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Xóa'),
        cancelButtonText: t('Hủy'),
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          deleteBankAccount(row.id)
            .then(() => {
              Swal.fire({
                text: t('Xóa tài khoản thành công'),
                icon: 'success',
              })
              fetchBankAccount()
            })
        }
      })
    }

    onMounted(() => {
      fetchBankAccount()
    })

    return {
      search,
      bankStatus,
      bankStatusOptions,

      // table
      fields,
      items,
      fetchBankAccount,

      // pagination
      pagination,
      changePage,

      // modal
      createBankAccountModal,
      updateBankAccountModal,

      // action
      editBankAccount,
      deleteBankAccountHandle,

      formatDateTimeDb,
    }
  },
}
</script>

<style scoped>
#bankStatus {
  min-width: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
