import store from '@/store'

export const useCreateBankAccount = () => {
  const createBankAccount = bankAccount => store.dispatch('bank-account/createBankAccount', bankAccount)
  const updateBankAccount = bankAccount => store.dispatch('bank-account/updateBankAccount', bankAccount)
  const getBankMasterData = () => store.dispatch('bank-account/getBankMasterData')

  return {
    createBankAccount,
    updateBankAccount,
    getBankMasterData,
  }
}

export const useListBankAccount = () => {
  const getBankAccounts = (...args) => store.dispatch('bank-account/getBankAccounts', ...args)
  const deleteBankAccount = (...args) => store.dispatch('bank-account/deleteBankAccount', ...args)

  return {
    getBankAccounts,
    deleteBankAccount,
  }
}

export const _ = null
