<template>
  <b-modal
    id="modal-create-bank-account"
    ref="modal"
    centered
    size="md"
    :title="$t('Thêm tài khoản rút tiền')"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="createBankAccountForm"
        #default="{invalid}"
      >
        <b-form
          @submit.prevent="createAccount"
        >
          <b-form-group
            :label="$t('Ngân hàng')"
            label-for="bank"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Ngân hàng')"
              vid="bank"
              rules="required"
            >
              <v-select
                id="bank"
                v-model="bank"
                :placeholder="$t('Lựa chọn ngân hàng')"
                :get-option-label="(option) => option.name"
                :options="bankOptions"
                :reduce="item => item.id"
                :state="errors.length > 0 ? false:null"
                :filter="fuseSearch"
              >
                <template #option="{ name, name_2 }">
                  {{ name }}
                  <br>
                  <cite>{{ name_2 }}</cite>
                </template>
                <template #search="{ attributes, events }">
                  <input
                    ref="search"
                    maxlength="20"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('Tên tài khoản')"
            label-for="accountName"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Tên tài khoản')"
              vid="accountName"
              rules="required|alpha-spaces|regex:^[A-Za-z ]+$"
            >
              <b-form-input
                id="accountName"
                v-model="accountName"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('Số tài khoản')"
            label-for="accountNumber"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Số tài khoản')"
              vid="accountNumber"
              rules="required|integer"
            >
              <b-form-input
                id="accountNumber"
                v-model="accountNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-button
                variant="secondary"
                class="mr-1"
                @click="$bvModal.hide('modal-create-bank-account')"
              >
                {{ $t('Hủy thêm mới') }}
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid"
                class="ml-1"
              >
                {{ $t('Thêm mới') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import {
  required, integer, alphaSpaces, regex,
} from '@validations'
import Swal from 'sweetalert2'
import { useI18n } from '@core/utils/utils'
import Fuse from 'fuse.js'
import { useCreateBankAccount } from './useBankAccount'

export default {
  name: 'CreateBankAccountModal',
  components: {
    BModal,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const modal = ref(null)
    const bank = ref(null)
    const search = ref(null)
    const bankOptions = ref([])
    const accountName = ref('')
    const accountNumber = ref('')

    const {
      createBankAccount,
      getBankMasterData,
    } = useCreateBankAccount()

    const createAccount = () => {
      createBankAccount({
        bank_master_id: bank.value,
        bank_number: accountNumber.value,
        bank_account: accountName.value,
        type: 'payout',
      })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: t('Thêm tài khoản rút tiền thành công'),
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: t('Thêm tài khoản rút tiền thất bại'),
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .finally(() => {
          modal.value.hide()
          emit('created')
        })
    }

    const show = () => {
      modal.value.show()
      bank.value = null
      accountName.value = ''
      accountNumber.value = ''
    }

    const fuseSearch = (options, s) => {
      const fuse = new Fuse(options, {
        keys: ['name', 'name_2'],
        shouldSort: true,
        threshold: 0.0,
        ignoreLocation: true,
      })
      return s.length
        ? fuse.search(s).map(({ item }) => item)
        : fuse.list
    }

    onMounted(async () => {
      bankOptions.value = await getBankMasterData()
    })

    return {
      modal,
      bank,
      search,
      bankOptions,
      accountName,
      accountNumber,
      createAccount,
      show,
      fuseSearch,

      // Validation
      required,
      integer,
      alphaSpaces,
      regex,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
